@use "../../styles/_variables.scss" as *;

.experience-container {
  margin-bottom: 18px;

  &.hidden {
    .experience {
      transform: translateY(40px);
      opacity: 0;
    }

    .company {
      transform: translateX(40px);
      opacity: 0;
    }
  }

  .experience {
    opacity: 1;
    transition: $fadeIn;
    margin-bottom: 12px;
  }

  .company {
    opacity: 1;
    transition: $fadeIn;

    .company-name {
      display: inline-block;
      margin-bottom: 6px;
      border-bottom: 2px solid $light-parchment;
      text-transform: uppercase;
    }

    .company-timespan {
      display: block;
      margin-bottom: 6px;
      color: $light-grey;
      font-size: 15px;
    }

    .relevant-skills {
      display: flex;
      flex-wrap: wrap;
      gap: 9px;
      margin-bottom: 12px;

      .skill {
        border-radius: 18px;
        background-color: rgba($royal-blue, 0.4);
        padding: 9px;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (min-width: $tablet) {
  .experience-container {
    display: flex;
    gap: 12px;
    margin-bottom: 0;

    .company {
      order: 2;
      width: 37%;
    }

    .experience {
      order: 1;
      width: 63%;
    }
  }
}
