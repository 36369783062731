@use "../../styles/_variables.scss" as *;

.top-navigation {
  --opacity: 0;
  display: flex;
  position: fixed;
  right: 0;
  flex-direction: column;
  align-items: flex-end;
  z-index: 999;
  padding: 18px;
  text-align: right;

  .navigation-items {
    display: none;
    padding: 0;

    .navigation-item {
      transition: 0.3s color;
      cursor: pointer;
      margin: 9px;
      color: $light-parchment;
      font-size: 27px;

      &::after {
        display: block;
        transform: scale(0);
        opacity: 0;
        transition: 0.2s opacity ease-in-out, 0.3s background-color, 0.3s transform;
        background-color: $light-parchment;
        width: 100%;
        height: 2px;
        content: "";
      }

      &.active::after {
        transform: scale(1);
        opacity: 100%;
      }

      &:hover {
        color: $peach;

        &::after {
          transform: scale(1);
          opacity: 100%;
          background-color: $peach;
        }
      }
    }
  }

  &.active {
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;

    &:before {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      background: linear-gradient(90deg, transparent 0%, $dark-purple 100%);
      width: 500px;
      height: 100%;
      content: "";
    }

    .navigation-items {
      display: block;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .top-navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, var(--opacity));
    border: 1px solid rgba($midnight-blue, calc(var(--opacity) * 2));
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    background-color: rgba($midnight-blue, var(--opacity));
    padding: 9px;
    width: 100%;
    font-size: 20px;

    .open-menu {
      display: none;
    }

    .navigation-items {
      display: flex;
      flex: 0 1 1150px;
      justify-content: flex-end;
      gap: 36px;
      margin: 0;
      list-style: none;

      .navigation-item {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .top-navigation {
    .navigation-items {
      justify-content: flex-end;
    }
  }
}
