@use "../../styles/_variables.scss" as *;

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  min-height: 100vh;
  font-size: 27px;
  text-align: center;

  .top-line {
    .hi {
      opacity: 0;
      animation: 1.2s linear 0.8s fadeInAnimation;
      animation-fill-mode: forwards;
    }

    .my-name-is {
      opacity: 0;
      animation: 1.2s linear 2.2s fadeInAnimation;
      animation-fill-mode: forwards;
    }
  }

  .middle-line {
    font-size: 63px;
    font-family: $parisienne;

    .typed-name {
      opacity: 0;
      animation: 2s linear 3.2s fadeInAnimation;
      animation-fill-mode: forwards;
    }

    .handwritten-name {
      display: none;
    }
  }

  .bottom-line {
    opacity: 0;
    animation: 2s linear 4.5s fadeInAnimation;
    animation-fill-mode: forwards;
  }

  .scroll-down-container {
    display: none;
    position: absolute;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: 2s linear 6s fadeInAnimation;
    animation-fill-mode: forwards;
    transition: bottom 0.4s ease-in-out;
    cursor: pointer;

    .scroll-down {
      position: relative;
      opacity: 0.6;
      transition: height 0.4s ease-in-out;
      border: 2px solid $light-parchment;
      border-radius: 15px;
      width: 30px;
      height: 44px;

      &::before {
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(50%);
        transition: all 0.4s ease-in-out;
        border-left: 2px solid $light-parchment;
        height: 10px;
        content: "";
      }

      &::after {
        position: absolute;
        bottom: calc(50% - 4px);
        left: 50%;
        transform: translateX(-50%) translateY(50%) rotateZ(-45deg);
        transition: bottom 0.4s ease-in-out;
        border-bottom: 2px solid $light-parchment;
        border-left: 2px solid $light-parchment;
        width: 4px;
        height: 4px;
        content: "";
      }
    }

    &:hover {
      bottom: 142px;

      .scroll-down {
        height: 60px;

        &::before {
          height: 26px;
        }

        &::after {
          bottom: calc(50% - 12px);
        }
      }
    }
  }
}

@media only screen and (min-width: $tablet) {
  .header-text {
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $midnight-blue;
      width: 100%;
      height: 2px;
      //content: "";
    }

    .top-line {
      font-size: 36px;
      text-align: left;
    }

    .bottom-line {
      animation-delay: 5.5s;
      font-size: 36px;
      text-align: right;
    }

    .scroll-down-container {
      display: block;
    }

    .middle-line {
      font-size: 0;
      text-align: center;

      .typed-name {
        display: none;
      }

      .handwritten-name {
        fill: $light-parchment;
        display: block;

        opacity: 0;
        animation: 0s linear 3s fadeInAnimation;
        animation-fill-mode: forwards;
        margin: auto;

        .mask {
          fill: black;
          stroke: $light-parchment;
          stroke-miterlimit: 10;
          transform: translate(var(--translate-x), var(--translate-y));
          animation: strokeOffset var(--animation-length) linear forwards var(--animation-delay);
        }
      }

      @keyframes strokeOffset {
        to {
          stroke-dashoffset: 0;
        }
      }

      #mask-B {
        --animation-length: 0.4s;
        --animation-delay: 3s;
        --translate-x: -5px;
        --translate-y: 0px;
      }
      #mask-e {
        --animation-length: 0.16s;
        --animation-delay: 3.4s;
        --translate-x: 118px;
        --translate-y: 44px;
      }
      #mask-n {
        --animation-length: 0.16s;
        --animation-delay: 3.56s;
        --translate-x: 165px;
        --translate-y: 44px;
      }
      #mask-d {
        --animation-length: 0.24s;
        --animation-delay: 3.72s;
        --translate-x: 219px;
        --translate-y: 5px;
      }
      #mask-e-2 {
        --animation-length: 0.16s;
        --animation-delay: 3.96s;
        --translate-x: 283px;
        --translate-y: 44px;
      }
      #mask-g {
        --animation-length: 0.32s;
        --animation-delay: 4.12s;
        --translate-x: 312px;
        --translate-y: 44px;
      }
      #mask-u {
        --animation-length: 0.24s;
        --animation-delay: 4.44s;
        --translate-x: 369px;
        --translate-y: 22px;
      }
      #mask-z {
        --animation-length: 0.16s;
        --animation-delay: 4.68s;
        --translate-x: 411px;
        --translate-y: 47px;
      }
      #mask-T {
        --animation-length: 0.36s;
        --animation-delay: 5.04s;
        --translate-x: 525px;
        --translate-y: -2px;
      }
      #mask-a {
        --animation-length: 0.16s;
        --animation-delay: 5.4s;
        --translate-x: 611px;
        --translate-y: 47px;
      }
      #mask-k {
        --animation-length: 0.36s;
        --animation-delay: 5.56s;
        --translate-x: 660px;
        --translate-y: 0px;
      }
      #mask-aa {
        --animation-length: 0.28s;
        --animation-delay: 5.92s;
        --translate-x: 708px;
        --translate-y: 23px;
      }
      #mask-c {
        --animation-length: 0.16s;
        --animation-delay: 6.2s;
        --translate-x: 767px;
        --translate-y: 38px;
      }
      #mask-s {
        --animation-length: 0.16s;
        --animation-delay: 6.36s;
        --translate-x: 806px;
        --translate-y: 36px;
      }
    }
  }
}
