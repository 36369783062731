@use "./styles/_variables.scss" as *;

.app-header {
  width: 100%;
}

.app-container {
  --background-rotation: 155deg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(var(--background-rotation), $sky-red 0%, $midnight-blue 90%);
  background-position: 50%;
  background-size: 200%;
  min-height: 100vh;
}

.app-body {
  box-sizing: border-box;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  max-width: 1400px;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
