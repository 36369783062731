@use "../../styles/variables.scss" as *;

.link {
  display: inline;
  transition: 0.3s color;
  cursor: pointer;
  font-weight: 400;

  a {
    color: $light-parchment;
    text-decoration: none;
  }
}

@media only screen and (min-width: $desktop) {
  .link {
    display: inline-block;
    a {
      &::after {
        display: block;
        transform: scale(0);
        opacity: 0;
        transition: 0.2s opacity ease-in-out, 0.3s background-color, 0.3s transform;
        background-color: $light-parchment;
        width: 100%;
        height: 2px;
        content: "";
      }

      &:hover {
        color: $peach;

        &::after {
          transform: scale(1);
          opacity: 100%;
          background-color: $peach;
        }
      }
    }
  }
}
