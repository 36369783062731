@use "../../styles/variables.scss" as *;

.full-image-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba($midnight-blue, 0.8);
  width: 100vw;
  height: 100vh;

  &.active {
    display: flex;
  }

  .full-image {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 70vw;
    height: 84vh;

    img {
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .close-overlay {
    position: absolute;
    top: -50px;
    right: 0;
    cursor: pointer;
  }

  .button {
    position: absolute;
    bottom: -4vh;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
  }
}
