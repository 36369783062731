@use "../../styles/variables.scss" as *;

.slider {
  margin-bottom: 72px;
  width: 100%;

  .slider-texts {
    opacity: 1;
    transition: $fadeIn;
    margin-bottom: 36px;

    &.hidden {
      transform: translateY(40px);
      opacity: 0;
    }

    &.right-aligned-texts {
      text-align: right;
    }

    .slider-title {
      font-weight: 500;
      font-size: 36px;
    }
    .slider-subtitle {
      font-size: 16px;
    }
  }

  .slider-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 18px;

    .slider-items {
      display: flex;
      position: relative;
      justify-content: center;
      gap: 18px;
      perspective: 1000px;
      opacity: 1;
      transition: $fadeIn;
      margin-bottom: 36px;

      &.hidden {
        transform: translateY(40px);
        opacity: 0;
      }

      .slider-item {
        --card-y-offset: 0;
        --card-depth-offset: -200px;
        --card-opacity-delay: 0.29s;
        position: absolute;
        transform: translateX(var(--card-y-offset)) translateZ(var(--card-depth-offset));
        opacity: 0;
        z-index: 1;
        transition: transform 0.7s cubic-bezier(0.65, 0.05, 0.36, 1), opacity 0.2s linear var(--card-opacity-delay),
          z-index 0s linear 0.33s;
        border: 2px solid $midnight-blue;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 90%;
        max-height: 500px;

        &.prev-item,
        &.next-item {
          --card-opacity-delay: 0.35s;
          --card-depth-offset: -100px;
          opacity: 0.6;
        }

        &.prev-item {
          --card-y-offset: -90%;
        }

        &.next-item {
          --card-y-offset: 90%;
        }

        &.current-item {
          --card-depth-offset: 0;
          position: relative;
          opacity: 1;
          z-index: 2;

          cursor: pointer;
        }
      }

      .slide-back,
      .slide-forward {
        display: none;
      }
    }

    .item-texts {
      position: relative;
      flex: 1;
      order: 2;
      opacity: 1;
      transition: $fadeIn;
      margin-bottom: 36px;

      &.hidden {
        transform: translateY(40px);
        opacity: 0;
      }

      .item-text-container {
        display: none;
        position: absolute;

        .item-title,
        .item-description {
          --text-position-offset: 0;
          transform: translateY(var(--text-position-offset));
          opacity: 0;
          transition: all 0.4s cubic-bezier(0.39, 0.58, 0.57, 1);
        }

        .item-title {
          font-weight: 500;
          font-size: 22px;
        }

        .item-description {
          transition-delay: 0.2s;
        }

        &.current-item {
          display: block;
          position: relative;

          &.fade-out {
            .item-title,
            .item-description {
              --text-position-offset: -60px;
              opacity: 0;
            }
          }

          .item-title,
          .item-description {
            opacity: 1;
          }
        }

        &.prev-item,
        &.next-item {
          &.fade-in {
            display: block;
          }

          .item-title,
          .item-description {
            --text-position-offset: 60px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $tablet) {
  .slider {
    .slider-container {
      flex-direction: row;

      .slider-items {
        display: block;
        width: 65%;

        &.hidden {
          transform: translateX(-40px);

          &.right-aligned {
            transform: translateX(40px);
          }
        }

        .slider-item {
          --card-scale-offset: 0.8;
          --card-rotation-offset: 0;
          --card-y-offset: 12%;
          top: 50%;
          left: 0;
          transform: translateY(-50%) translateX(var(--card-y-offset)) rotateY(var(--card-rotation-offset))
            scale(var(--card-scale-offset)) translateZ(var(--card-depth-offset));
          width: 80%;

          &.prev-item {
            --card-rotation-offset: 25deg;
            --card-y-offset: -7%;
          }

          &.next-item {
            --card-rotation-offset: -25deg;
            --card-y-offset: 31%;
          }

          &.current-item {
            --card-scale-offset: 1;
            --card-depth-offset: 0;
            position: relative;
            opacity: 1;
          }
        }

        .slide-back,
        .slide-forward {
          --button-rotation-offset: 0;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotateZ(var(--button-rotation-offset));
          z-index: 3;
          transition: 0.2s border-color ease-in-out;
          border-top: 5px solid $royal-blue;
          border-right: none;
          border-bottom: none;
          border-left: 5px solid $royal-blue;
          background: none;
          width: 20px;
          height: 20px;

          &:hover {
            cursor: pointer;
            border-color: $midnight-blue;
          }
        }

        .slide-back {
          --button-rotation-offset: -45deg;
          left: 5%;
        }

        .slide-forward {
          --button-rotation-offset: 135deg;
          right: 5%;
        }
      }

      .item-texts {
        order: unset;

        &.hidden {
          transform: translateX(-40px);

          &.right-aligned {
            transform: translateX(40px);
          }
        }

        &.right-aligned {
          order: 2;
        }

        .item-text-container {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
