@use "./styles/_variables.scss" as *;
@use "./styles/fonts";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  color: $light-parchment;
  font-weight: 200;
  font-size: 18px;
  line-height: 1.5;
  font-family: $manrope;
}
