$light-parchment: #f6f2e5;
$light-grey: #cfc8cf;
$peach: #f6c1a2;
$grey-blue: #a1abb5;
$royal-blue: #4144e9;
$midnight-blue: #0b0b29;
$russian-violet: #4c285f;
$sky-red: #682a2e;
$dark-purple: #35192b;

$sen: "Sen", sans-serif;
$manrope: "Manrope", sans-serif;
$parisienne: "Parisienne", cursive;

$tablet: 768px;
$desktop: 992px;

$fadeIn: all 1.5s ease-in-out;
$fadeInQuick: all 0.5s ease-in-out;
