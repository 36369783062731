@use "../../styles/_variables.scss" as *;

.about-me-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin: auto;
  margin-bottom: 135px;

  .about-me-image {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: $fadeIn;
    transition-delay: 0.3s;

    &.hidden {
      transform: translateY(40px);
      opacity: 0;
    }

    img {
      border: 2px solid $midnight-blue;
      width: 100%;
      max-width: 360px;
    }
  }

  .about-me {
    font-size: 16px;

    .about-me-text {
      opacity: 1;
      transition: $fadeIn;
      margin-bottom: 18px;

      &.hidden {
        transform: translateY(40px);
        opacity: 0;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .about-me-container {
    flex-direction: row;
    align-items: flex-start;

    .about-me-image {
      justify-content: flex-start;
      width: 30%;
    }

    .about-me {
      max-width: 68%;
    }
  }
}
